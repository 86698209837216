import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
// import "./assets/sass/main.scss";

import "./styles/app.sass";
import { useEffect } from "react";
import Page from "./components/Page";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "utils/constants.js";
import { setLoggedIn, setUserId } from "store/slices/userSlice.js";
import "react-circular-progressbar/dist/styles.css";
import Spot from "./screens/Spot";
import { setAvailablePairs } from "store/symbolDataSlice.js";
import api from "utils/api.js";
import { Toaster } from "react-hot-toast";
import useDarkMode from "@fisch0920/use-dark-mode";
import Scrollbar from "components/Scrollbar/index.jsx";
import "./assets/sass/main.scss";
import helper from "utils/helper.js";
import { supportedLangs } from "utils/defaults.js";
import i18n from "i18n.js";
function App() {
  const darkMode = useDarkMode(false);

  const dispatch = useDispatch();

  const symbol = useSelector((state) => state.symbolData.symbol);
  const price = useSelector((state) => state.symbolData.price);

  useEffect(() => {
    helper.setTitle(`${helper.formatValue(price, 2)}-${symbol}`);
  }, [symbol, price]);
  useEffect(() => {
    const handleMessage = (e) => {
      let data = e.data;
      if (data.action === "switchTheme") {
        if (darkMode.value !== data.data) {
          darkMode.toggle();
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [darkMode]);
  async function checkLoggedIn() {
    try {
      let userSessionId = Cookies.get("PHPSESSID");
      console.log("cookie", userSessionId);
      let response = await fetch(`${baseUrl}/user/verifyLogin`, {
        method: "POST",
        credentials: "include", // Include cookies in the request
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phpSessionId: userSessionId,
        }),
      });
      let result = await response.json();
      let authenticated = result.body.authenticated;
      dispatch(setLoggedIn(authenticated));
      dispatch(setUserId(result.body.userId));
    } catch (error) {
      console.log("something went wrong", error);
    }
  }

  const refreshTradingPairs = async () => {
    let pairs = await api.fetchTradingPairs();
    dispatch(setAvailablePairs(pairs));
  };

  useEffect(() => {
    refreshTradingPairs();
  }, []);

  useEffect(() => {
    checkLoggedIn();
  }, []);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Scrollbar>
        <div>
          <Router>
            <Switch>
              <Route
                exact
                path="/spot/:symbol"
                render={() => (
                  <Page headerWide footerHide>
                    <Spot />
                  </Page>
                )}
              />
              <Route
                exact
                path="/spot/"
                render={() => <Redirect to="/spot/ETHUSDT" />}
              />
            </Switch>
          </Router>
          <Toaster
            toastOptions={{
              duration: 2000,
              blank: {
                style: {
                  background: darkMode.value ? "#23262F" : "#FCFCFD",
                  color: darkMode.value ? "#FCFCFD" : "#141416",
                },
              },
              success: {
                style: {
                  background: darkMode.value ? "#23262F" : "#FCFCFD",
                  color: "#58BD7D",
                },
              },
              error: {
                style: {
                  background: darkMode.value ? "#23262F" : "#FCFCFD",
                  color: "#FF3B30",
                },
              },
              loading: {
                style: {
                  background: darkMode.value ? "#23262F" : "#FCFCFD",
                  color: darkMode.value ? "#FCFCFD" : "#23262F",
                },
              },
            }}
            position="top-right"
          />
        </div>
      </Scrollbar>
    </div>
  );
}

export default App;
