/**
 * Reponse Format ->
 * {
 *   data : {
 *    lastPrice : string  last traded price
 *    volume : string accumlated volume in 24hrs
 *   } | null,
 *   error : string | null
 * }
 *
 */

export const fetchStatData = async (symbol) => {
  try {
    //Logic dependent upon each exchange
    if (symbol === "GOLDKRW") {
      let url = `https://api.binance.com/api/v3/ticker/24hr?symbol=PAXGUSDT`;
      let result = await fetch(url);
      let data = await result.json();
      let usdKrwPrice = await fetchUSDKRWPairPrice();
      return {
        data: {
          lastPrice: (parseFloat(data.lastPrice) * usdKrwPrice).toString(),
          volume: (parseFloat(data.quoteVolume) * usdKrwPrice).toString(),
        },
        error: null,
      };
    }
    if (symbol === "USDKRW") {
      let url = `https://api.bithumb.com/v1/ticker?markets=KRW-USDT`;
      let result = await fetch(url);
      let data =( await result.json())[0];
      return {
        data: {
          lastPrice: data.trade_price.toString(),
          volume: data.acc_trade_volume_24h.toString(),
        },
        error: null,
      };
    }
    throw new Error("Invalid symbol");
  } catch (error) {
    console.log(error);
    return {
      data: null,
      error: error.toString(),
    };
  }
};

const fetchUSDKRWPairPrice = async () => {
  let url = `https://api.bithumb.com/v1/ticker?markets=KRW-USDT`;
  let result = await fetch(url);
  result = (await result.json())[0];
  return parseFloat(result.trade_price);
};
