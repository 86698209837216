import { createSlice } from "@reduxjs/toolkit";
import { TRADING_TYPE } from "utils/types.js";

function getTodayMidnightDate() {
  // Create a new Date object for today's date
  let today = new Date();

  // Set time to midnight (00:00:00.000)
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  return today.getTime();
}
function generateRecentTrades() {
  let trades = [];
  while (trades.length < 20) {
    trades.push({
      id: trades.length,
      price: 0,
      qty: 0,
      createdAt: getTodayMidnightDate(),
      increase: false,
    });
  }
  return trades;
}
let initialState = {
  tradingType: TRADING_TYPE.spot,
  prevSymbol: "ETHUSDT",
  symbol: "ETHUSDT",
  defaultSymbol: "ETHUSDT",
  prevPrice: 67000,
  price: 68000,
  binancePrice: 80000,
  binanceData: {
    lastPrice: 60000,
    volume: 10000,
  },
  allBinanceDataMap: {},
  precesion: 6,
  orderbook: {
    asks: [
      { price: 0, qty: 0 },
      { price: 0, qty: 0 },
      { price: 0, qty: 0 },
      { price: 0, qty: 0 },
      { price: 0, qty: 0 },
      { price: 0, qty: 0 },
    ],
    bids: [
      { price: 0, qty: 0 },
      { price: 0, qty: 0 },
      { price: 0, qty: 0 },
      { price: 0, qty: 0 },
      { price: 0, qty: 0 },
      { price: 0, qty: 0 },
    ],
    latestTrade: {
      price: 0,
      amount: 0,
      increase: false,
    },
    totalAskQty: 0,
    totalBidQty: 0,
  },
  prevPairData: {},
  pairInfo: {
    baseTokenSymbol: "ETH",
    quoteTokenSymbol: "USDT",
    maxLeverage: 10,
    openDate: "",
  },
  availablePairs: [],
  recentTrades: generateRecentTrades(),
};
export const symbolDataSlice = createSlice({
  name: "symbolData",
  initialState: initialState,
  reducers: {
    setPrecesion: (state, action) => {
      state.precesion = action.payload;
    },
    switchPair: (state, action) => {
      let tradeType = action.payload.tradeType;
      let enabledKey =
        tradeType === TRADING_TYPE.spot
          ? "spotTradingEnabled"
          : tradeType === TRADING_TYPE.futures
          ? "marginTradingEnabled"
          : "optionsTradingEnabled";
      let newPairInfo = state.availablePairs.find(
        (pair) => pair.symbol === action.payload.symbol && pair[enabledKey]
      );
      if (!newPairInfo) return;
      state.prevSymbol = state.symbol;
      state.prevPairData = state.pairInfo;
      state.pairInfo = newPairInfo;
      state.precesion = newPairInfo.precision;
      state.symbol = action.payload.symbol;
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setBinanceData: (state, action) => {
      state.binanceData = action.payload;
    },
    setAllBinanceDataMap: (state, action) => {
      state.allBinanceDataMap = action.payload;
    },
    setOrderBook: (state, action) => {
      state.orderbook = {
        ...state.orderbook,
        ...action.payload,
      };
    },
    setRecentTrades: (state, action) => {
      let trades = action.payload;
      for (let i = 0; i < trades.length; i++) {
        trades[i].createdAt = new Date(trades[i].createdAt).getTime();
      }
      while (trades.length < 20) {
        trades.push({
          id: trades.length,
          qty: 0,
          price: 0,
          createdAt: getTodayMidnightDate(),
        });
      }
      trades[trades.length - 1].increase = false;
      for (let i = trades.length - 2; i >= 0; i--) {
        trades[i].increase =
          parseFloat(trades[i].price) > parseFloat(trades[i + 1].price);
        if (parseFloat(trades[i].price) === parseFloat(trades[i + 1].price)) {
          trades[i].increase = trades[i + 1].increase;
        }
      }
      state.recentTrades = trades;
    },
    setPairInfo: (state, action) => {
      state.pairInfo = action.payload;
    },

    setAvailablePairs: (state, action) => {
      state.availablePairs = action.payload;
    },
    setTradingType: (state, action) => {
      state.tradingType = action.payload;
    },
  },
});

export const {
  switchPair,
  setPrice,
  setOrderBook,
  setRecentTrades,
  setPairInfo,
  setAvailablePairs,
  setBinanceData,
  setAllBinanceDataMap,
  setPrecesion,
} = symbolDataSlice.actions;
export default symbolDataSlice.reducer;
