import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Action.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../../../../components/Icon";
import { useSelector } from "react-redux";
import helper from "utils/helper.js";
import { Link } from "react-router-dom";
import { ORDER_TYPE, SPOT_TRADE_TYPE } from "utils/types.js";
import toast from "react-hot-toast";
import api from "utils/api.js";
import bride from "utils/php_bridge.js";
import { useTranslation } from "react-i18next";
import { deci, deciValNZ, deciVal, fixedOf } from "utils/decimal.js";
import { isNonDivisibleToken } from "utils/roundUtils.js";
const Action = ({ content, market, stop, limit, classButton, isBuy }) => {
  //TODO : Handle signup and login button clicks
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const baseTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.baseTokenSymbol
  );
  const quoteTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.quoteTokenSymbol
  );

  const userBalance = useSelector((state) => state.user.balance);

  const tradingPair = useSelector((state) => state.symbolData.symbol);
  const currentPrice = useSelector((state) => state.symbolData.price);
  const precesion = useSelector((state) => state.symbolData.precesion);

  const [limitPrice, setLimitPrice] = useState("");
  const [stopPrice, setStopPrice] = useState("");
  const [orderValue, setOrderValue] = useState("0");
  const [orderQuantity, setOrderQuantity] = useState("");
  const [values, setValues] = useState([10]);

  const stepPrice = 1;
  const minPrice = 0;
  const maxPrice = 100;

  function isValidNumber(str) {
    const regex = /^-?\d+(\.\d+)?$|^-?\d+\.$/;
    return regex.test(str);
  }
  function isValidInteger(str) {
    const regex = /^-?\d+$/;
    return regex.test(str);
  }

  function updateIfValid(value, trigger, onlyInteger = false) {
    if (value.length === 0) {
      trigger(value);
    }
    if (onlyInteger && isValidInteger(value)) {
      trigger(value);
    }
    if (!onlyInteger && isValidNumber(value)) {
      trigger(value);
    }
  }
  const placeOrder = async () => {
    try {
      //TODO : verify and show popup for valid parameters
      // Ex : limit , stop , quantity

      // symbol,
      // baseTokenAmount,
      // quoteTokenAmount,
      // orderType,
      // tradeType,
      // limitPrice,
      // stopPrice,

      const body = {
        symbol: tradingPair,
        baseTokenAmount: orderQuantity,
        quoteTokenAmount: null,
        orderType: stop
          ? ORDER_TYPE.stop_market
          : limit
          ? ORDER_TYPE.limit
          : ORDER_TYPE.market,
        tradeType: isBuy ? SPOT_TRADE_TYPE.buy : SPOT_TRADE_TYPE.sell,
        limitPrice: limitPrice,
        stopPrice: stopPrice,
      };
      toast.promise(
        api.placeOrderSpot(body),
        {
          success: (result) => t("toast_messages.order_placed"),
          error: (err) => err.toString(),
          loading: t("toast_messages.processing"),
        },
        {
          style: {
            minWidth: "250px",
          },
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(t("toast_messages.something_wrong"));
    }
  };
  useEffect(() => {
    if (!isBuy) {
      setOrderValue(
        helper.formatValue(parseFloat(orderQuantity) * currentPrice, precesion)
      );
    }
    //TODO : verify whether using currentPrice , isBuy in dependency array is necessary
  }, [orderQuantity, precesion]);

  const getUnlockedBalance = (balance) => {
    return fixedOf(deci(balance.spot).sub(balance.lockedSpot));
  };

  const updateInputValues = (values) => {
    if (!userBalance[quoteTokenSymbol] || !userBalance[baseTokenSymbol]) return;
    setOrderValue(
      deci(values[0])
        .mul(getUnlockedBalance(userBalance[quoteTokenSymbol]))
        .div(100)
        .toString()
    );

    if (isBuy) {
      if (market) return;

      setOrderQuantity(
        deci(values[0])
          .mul(getUnlockedBalance(userBalance[quoteTokenSymbol]))
          .div(100)
          .div(deciValNZ(limitPrice, currentPrice ?? 1))
          .toString()
      );
    } else {
      setOrderQuantity(
        deci(values[0])
          .mul(getUnlockedBalance(userBalance[baseTokenSymbol]))
          .div(100)
          .toString()
      );
    }
  };

  // useEffect(() => {
  //   updateInputValues(values);
  // }, [limitPrice]);

  useEffect(() => {
    if (!userBalance[quoteTokenSymbol] || !userBalance[baseTokenSymbol]) return;
    if (isBuy) {
      if (market) return;
      let percentage = deci(deciVal(orderQuantity, 0))
        .mul(deciVal(limitPrice, currentPrice))
        .mul(100)
        .div(getUnlockedBalance(userBalance[quoteTokenSymbol]))
        .toFixed(2);
      setValues([parseFloat(percentage)]);
    } else {
      let percentage = deci(deciVal(orderQuantity, 0))
        .mul(100)
        .div(getUnlockedBalance(userBalance[baseTokenSymbol]))
        .toFixed(2);
      setValues([parseFloat(percentage)]);
    }
  }, [orderQuantity, orderValue, limitPrice]);

  useEffect(() => {}, []);

  return (
    <>
      <div className={styles.head}>
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <div>{t("actions.available_balance")}</div>
          <Icon name="wallet" size="16" className={cn("styled-icon")} />{" "}
        </div>

        {userBalance[quoteTokenSymbol] && userBalance[baseTokenSymbol] && (
          <div style={{ fontWeight: "500" }}>
            {helper.formatValue(
              isBuy
                ? getUnlockedBalance(userBalance[quoteTokenSymbol])
                : getUnlockedBalance(userBalance[baseTokenSymbol]),
              9
            )}
          </div>
        )}
      </div>
      {stop && (
        <div>
          <div className={styles.label}>{t("actions.stop")}</div>
          <label className={styles.field}>
            <input
              className={styles.input}
              type="text"
              name="stop"
              required
              value={stopPrice}
              onChange={(evt) => {
                updateIfValid(
                  evt.target.value,
                  setStopPrice,
                  isNonDivisibleToken(quoteTokenSymbol)
                );
              }}
            />
            <div className={styles.currency}>{quoteTokenSymbol}</div>
          </label>
        </div>
      )}
      {limit && (
        <div>
          <div className={styles.label}>{t("actions.limit")}</div>
          <label className={styles.field}>
            <input
              className={styles.input}
              type="text"
              name="limit"
              required
              value={limitPrice}
              onChange={(evt) => {
                updateIfValid(
                  evt.target.value,
                  setLimitPrice,
                  isNonDivisibleToken(quoteTokenSymbol)
                );
              }}
            />
            <div className={styles.currency}>{quoteTokenSymbol}</div>
          </label>
        </div>
      )}
      <div>
        <div className={styles.label}>{t("actions.amount")}</div>
        <label className={styles.field}>
          <input
            className={styles.input}
            type="text"
            name="amount"
            required
            value={orderQuantity}
            onChange={(evt) =>
              updateIfValid(evt.target.value, setOrderQuantity)
            }
          />
          <div className={styles.currency}>{baseTokenSymbol}</div>
        </label>
      </div>

      {!(isBuy && market) && (
        <Range
          values={values}
          step={stepPrice}
          min={minPrice}
          max={maxPrice}
          onChange={(values) => {
            setValues(values);
            updateInputValues(values);
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "2px",
                  width: "100%",
                  borderRadius: "1px",
                  background: getTrackBackground({
                    values,
                    colors: ["#3772FF", "#E6E8EC"],
                    min: minPrice,
                    max: maxPrice,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "18px",
                width: "18px",
                borderRadius: "50%",
                backgroundColor: "#F4F5F6",
                border: "4px solid #777E90",
                boxShadow: "0px 8px 16px -8px rgba(15, 15, 15, 0.2)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isDragged && (
                <div
                  style={{
                    position: "absolute",
                    top: "-27px",
                    color: "#FCFCFD",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "16px",
                    fontFamily: "Poppins",
                    padding: "2px 6px",
                    borderRadius: "6px",
                    backgroundColor: "#777E90",
                  }}
                >
                  {values[0].toFixed(0)}%
                </div>
              )}
            </div>
          )}
        />
      )}

      {isLoggedIn && (
        <button
          className={cn(classButton, styles.button)}
          onClick={placeOrder}
          style={{
            background: isBuy ? "#58BD7D" : "#FF6838",
          }}
        >
          {`${isBuy ? t("actions.buy") : t("actions.sell")} ${baseTokenSymbol}`}
        </button>
      )}
      {!isLoggedIn && (
        <div className={styles.btns}>
          <Link
            className={cn("button", styles.button)}
            activeClassName={styles.active}
            to="/sign-up"
            onClick={(event) => {
              event.preventDefault();
              bride.route("/sign-up.php");
            }}
          >
            {t("actions.sign_up")}
          </Link>
          <Link
            style={{ marginTop: "8px" }}
            className={cn("button-stroke", styles.button)}
            activeClassName={styles.active}
            to="/sign-in"
            onClick={(event) => {
              event.preventDefault();
              bride.route("/sign-in.php");
            }}
          >
            {t("actions.login")}
          </Link>
        </div>
      )}
    </>
  );
};

export default Action;
